import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Grid, TextField, Accordion, AccordionSummary, AccordionDetails, Radio, RadioGroup, FormControlLabel, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';


const HospitalList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredHospitals, setFilteredHospitals] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [hospitals, setHospitals] = useState([]);
  const navigate = useNavigate();  // React Router's navigation hook

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = hospitals.filter(hospital =>
      hospital.name.toLowerCase().includes(value) || hospital.city.toLowerCase().includes(value)
    );
    setFilteredHospitals(filtered);
  };

  const handleDetailsClick = (id) => {
    if(id){
      navigate(`/location-admin/${id}`);
    }
  };

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const response = await fetch('https://jbh-fhir.azurewebsites.net/api/hospitallocation');
        if (!response.ok) {
          throw new Error('Failed to fetch hospital data');
        }
        const data = await response.json();
        setHospitals(data);
        setFilteredHospitals(data);
      } catch (error) {
        console.error('Error fetching hospital data:', error);
      }
    };

    fetchHospitals();
  }, []);
  

  const handleSelectionChange = (event) => {
    setSelectedHospital(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedHospital) {
      // Navigate to /transport-list
      navigate('/transport-list');
    } else {
      alert('Please select a hospital before submitting.');
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>Hospital Directory</Typography>

      {/* Submit Button */}
      <Box mb={1} mt={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedHospital}
        >
          Submit
        </Button>
      </Box>
      {/* Search Bar */}
      <Box mb={3}>
        <TextField
          fullWidth
          label="Search by Hospital Name or City"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Box>

      {/* Hospital Cards with Selection */}
      <RadioGroup value={selectedHospital} onChange={handleSelectionChange}>
        <Grid container spacing={3}>
        {filteredHospitals.map((hospital, index) => (
            <Grid item xs={12} sm={6} md={4} key={hospital.id || index}>
              <Card>
                <CardContent>
                  <FormControlLabel
                    value={hospital.name}
                    control={<Radio />}
                    label={<Typography variant="h6">{hospital.name}</Typography>}
                  />
                  <Button onClick={() => handleDetailsClick(hospital.id)}>
                    Details
                  </Button>
                  <Typography variant="body2" color="textSecondary">{hospital.address}</Typography>
                  <Typography variant="body1" mt={1}>City: {hospital.city}</Typography>
                  <Typography variant="body1">Transfer Phone: {hospital.transferNumber}</Typography>

                  {/* Expandable Details */}
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>View More Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2">Acute Beds: {hospital.acuteBeds}</Typography>
                      <Typography variant="body2">ICU Beds: {hospital.icuBeds}</Typography>
                      <Typography variant="body2">Psych Beds: {hospital.psychBeds}</Typography>
                      <Typography variant="body2">SNF Beds: {hospital.snfBeds}</Typography>
                      <Typography variant="body2">Management: {hospital.management}</Typography>
                      <Typography variant="body2">WA State Member: {hospital.wa_state_member}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </RadioGroup>

    </Box>
  );
};


export default HospitalList;
