import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix the default icon issue in Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Injury_icon_2.svg/300px-Injury_icon_2.svg.png',
  iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Injury_icon_2.svg/300px-Injury_icon_2.svg.png',
  iconSize: [25, 25],
  shadowSize: [0, 0],
  iconAnchor: [0,0]
});

const HospitalMap = () => {
  const [hospitals, setHospitals] = useState([]);
  const [filteredHospitals, setFilteredHospitals] = useState([]);
  const [availableServices, setAvailableServices] = useState([]); // Dynamic filters
  const [filteredServices, setFilteredServices] = useState([]); // For searchable services
  const [selectedServices, setSelectedServices] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // Search bar query

  useEffect(() => {
    // Fetch hospital locations from API
    fetch('https://jbh-fhir.azurewebsites.net/api/hospitallocation')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch hospital data');
        }
        return response.json();
      })
      .then(data => {
        const formattedData = data.map(hospital => ({
          id: hospital.id,
          name: hospital.name,
          address: `${hospital.address}, ${hospital.city}, ${hospital.state}`,
          lat: hospital.latitude,
          lng: hospital.longitude,
          services: hospital.services || [],
        }));

        // Extract unique services dynamically and sort them alphabetically
        const allServices = formattedData.flatMap(hospital => hospital.services.map(s => s.name));
        const uniqueServices = Array.from(new Set(allServices)).sort();

        setAvailableServices(uniqueServices);
        setFilteredServices(uniqueServices); // Initialize searchable services
        setHospitals(formattedData);
        setFilteredHospitals(formattedData);
      })
      .catch(error => console.error('Error fetching hospital data:', error));
  }, []);

  const handleServiceChange = (service) => {
    setSelectedServices(prevState =>
      prevState.includes(service)
        ? prevState.filter(s => s !== service)
        : [...prevState, service]
    );
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter available services based on search input
    const filtered = availableServices.filter(service =>
      service.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredServices(filtered);
  };

  useEffect(() => {
    if (selectedServices.length === 0) {
      setFilteredHospitals(hospitals);
    } else {
      setFilteredHospitals(
        hospitals.filter(hospital =>
          selectedServices.every(service =>
            hospital.services.some(s => s.name === service)
          )
        )
      );
    }
  }, [selectedServices, hospitals]);

  return (
    <div className="map-container" style={{ display: 'flex' }}>
      {/* Sidebar for filters */}
      <div
        className="sidebar"
        style={{
          width: '300px',
          padding: '10px',
          backgroundColor: '#f9f9f9',
          overflowY: 'auto',
          maxHeight: '80vh',
        }}
      >
        <h3>Filter by Services</h3>
        
        {/* Search Bar */}
        <input
          type="text"
          placeholder="Search services..."
          value={searchQuery}
          onChange={handleSearchChange}
          style={{
            width: '100%',
            marginBottom: '10px',
            padding: '5px',
            boxSizing: 'border-box',
          }}
        />

        {/* Filter List */}
        {filteredServices.map(service => (
          <div key={service}>
            <input
              type="checkbox"
              id={service}
              value={service}
              checked={selectedServices.includes(service)}
              onChange={() => handleServiceChange(service)}
            />
            <label htmlFor={service}>{service}</label>
          </div>
        ))}
      </div>

      {/* Map Container */}
      <MapContainer center={[47.751076, -120.740135]} zoom={7} style={{ height: '80vh', flex: 1 }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {filteredHospitals.map(hospital => (
          <Marker key={hospital.id} position={[hospital.lat, hospital.lng]}>
            <Popup>
              <b>{hospital.name}</b>
              <br />
              {hospital.address}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default HospitalMap;
