import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import { useParams } from 'react-router-dom';

const HospitalProfile = () => {
  const [hospitalName, setHospitalName] = useState("");
  const [address, setAddress] = useState("");
  const [contacts, setContacts] = useState([]);
  const [newContact, setNewContact] = useState("");
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [loading, setLoading] = useState(false);
  const { id } = useParams();  // Get ID from route parameters if you have routing set up (e.g., /transferform/:id)
  

  //const apiBaseUrl = "https://localhost:44304/api/HospitalLocation"; // Replace with your actual API base URL

  const apiBaseUrl = "https://jbh-fhir.azurewebsites.net/api/HospitalLocation"; // Replace with your actual API base URL

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: coordinates.lat,
    lng: coordinates.lng,
  };


  // Fetch hospital data on mount
  useEffect(() => {
    const fetchHospitalData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/${id}`);
        const data = response.data;
        
        setHospitalName(data.name);
        setAddress(data.address);
        setCoordinates({ lat: data.latitude, lng: data.longitude });
        setContacts(data.contacts);
        setTags(data.services.map(service => service.name));
        geocodeAddress(data.address);  // Assuming services have a `tag` property
      } catch (error) {
        console.error("Error fetching hospital data:", error);
      }
    };

    fetchHospitalData();
  }, [id]);

  /* Save data to API
  const handleSave = async () => {
    const hospitalData = {
      id: id,
      name: hospitalName,
      address,
      latitude: coordinates.lat,
      longitude: coordinates.lng,
      contacts,
      services: tags.map(tag => ({ name: tag, specialty: "", description: "", tag })),
    };

    try {
      await axios.put(`${apiBaseUrl}/${id}`, hospitalData);
      alert("Hospital profile saved successfully.");
    } catch (error) {
      console.error("Error saving hospital data:", error);
      alert("Failed to save hospital profile.");
    }
  };*/

  const handleAddContact = () => {
    if (newContact.name.trim() !== "") {
      setContacts([...contacts, newContact]);
      setNewContact("");
    }
  };

  const handleRemoveContact = (contactToRemove) => {
    setContacts(contacts.filter((contact) => contact !== contactToRemove));
  };

  const handleAddTag = () => {
    if (newTag.trim() !== "") {
      setTags([...tags, newTag]);
      setNewTag("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const geocodeAddress = async (addy) => {
    setLoading(true);
    let useAddy = address;
    if(addy){
      useAddy = addy;
    }
    const apiKey = 'b88472c4606e4f4dafb048075349230d'; // Replace with your OpenCage API key
    const requestUrl = `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(useAddy)}&key=${apiKey}`;

    try {
      const response = await axios.get(requestUrl);
      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry;
        setCoordinates({ lat, lng });
      } else {
        alert("No results found. Please try a different address.");
      }
    } catch (error) {
      console.error("Failed to fetch geocoding data:", error);
      alert("Error fetching geocoding data. Please check console for details.");
    }
    setLoading(false);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleGeocodeSubmit = () => {
    geocodeAddress();
  };

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        Hospital Profile
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Hospital Name"
            value={hospitalName}
            onChange={(e) => setHospitalName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Address"
            value={address}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleGeocodeSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Set Location on Map"}
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
  {/* Left Column: Google Map */}
  <Grid item xs={12} md={6}>
    <Typography variant="h6">Hospital Location</Typography>
    <LoadScript googleMapsApiKey="AIzaSyD54j-aLa0TYd7SnwYSEaJ1m3jyl71yYyA">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  </Grid>

  {/* Right Column: Contact and Services Management */}
  <Grid item xs={12} md={6}>
    {/* Contact Management Section */}
    <Typography variant="h6">Hospital Contacts</Typography>
    <List>
          {contacts.map((contact, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={`${contact.name} (Position: ${contact.position}, Phone: ${contact.telephone})`}
                secondary={`Office Hours: ${contact.officeHours}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemoveContact(contact)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Contact Name"
          value={newContact.name}
          onChange={(e) => setNewContact({ ...newContact, name: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Position"
          value={newContact.position}
          onChange={(e) => setNewContact({ ...newContact, position: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Phone Number"
          value={newContact.phone}
          onChange={(e) => setNewContact({ ...newContact, phone: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Office Hours"
          value={newContact.hours}
          onChange={(e) => setNewContact({ ...newContact, hours: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddContact}
          fullWidth
        >
          Add Contact
        </Button>
        
      </Grid>

    </Grid>

    {/* Tag Management Section */}
    <Box mt={4}>
      <Typography variant="h6">Medical Services Tags</Typography>
      
      {tags.map((tag, index) => (
        <Chip
          key={index}
          label={tag}
          onDelete={() => handleRemoveTag(tag)}
          style={{ margin: "5px" }}
        />
      ))}
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            label="Add New Tag"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddTag}
            fullWidth
          >
            Add Tag
          </Button>
        </Grid>
      </Grid>

    </Box>
  </Grid>
</Grid>
    </Box>
  );
};

export default HospitalProfile;
